import { Injectable } from '@angular/core';
import { catchError, forkJoin, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { RootStore, User } from '../store/root.store';
import { Router } from '@angular/router';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { UserService } from 'src/app/services/user.service';
import { EXTERNAL_ROLES } from '../constants/c';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient, 
    private store: RootStore,
    private router: Router,
    private featureFlagService: FeatureFlagService,
    private userService: UserService
  ) { }

  public signInWithGoogle() {
    const url: any = `${environment.apiUrl}/google`;
    window.location = url;
  }

  public aocSSO(): void {
    const url: any = `${environment.apiUrl}/azure`;
    window.location = url;
  }

  public login(body: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/login`, body).pipe(catchError((err) => of(err)));
  }

  public onSuccess(user: any): void {
    const email = user.username;

    if (user.token) {
      sessionStorage.setItem('token', user.token);
    }
    const roles = user.userHasRoles
        .filter((userHasRole:any) => userHasRole.roles)
        .map((userHasRole:any) => userHasRole.roles.name)!;

    this.store.currentUser$.next({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
      roleNames:roles.join(','),
      isAdmin: (roles.includes('Admin'))
    });

    // Check if the user has an external role
    const externalRole = EXTERNAL_ROLES.find((role) => roles.includes(role.name));

    // Check if data has already been fetched
      forkJoin({
        flags: this.featureFlagService.getAllFeatureFlags(),
        permissions: this.userService.getUserPermissionsWithDetails()
      }).subscribe(
        ({ flags, permissions }) => {
          this.store.featureFlags$.next(flags);
          this.store.userPermissions$.next(permissions);
          if (externalRole) {
            this.router.navigateByUrl(externalRole.defaultPage);
          } else {
            this.router.navigateByUrl('/dashboard');  
          }
        },
        (error) => {
          console.error('Error fetching data:', error);
          if (externalRole) {
            this.router.navigateByUrl(externalRole.defaultPage);
          } else {
            this.router.navigateByUrl('/dashboard');
          }
        }
      );

  }

  public signUp(body: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/signup`, body).pipe(catchError((err) => of(err)));
  }

  public getUser(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/profile`).pipe(
      tap((user: any) => {
        const roles = user.userHasRoles
        .filter((userHasRole:any) => userHasRole.roles)
        .map((userHasRole:any) => userHasRole.roles.name)!;

        this.store.currentUser$.next({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
          roleNames:roles.join(','),
          isAdmin: (roles.includes('Admin'))
        });
      }),
      catchError((err) => {
        if (err.error) {
          this.router.navigateByUrl('/login');
          sessionStorage.removeItem('token');
        }
        return of(err);
      })
    );
  }

  public getUsersName(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/get-users-name`).pipe(catchError((err) => of(err)));
  }
  
  public getHHUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/users/health-home`);
  }

  public getNurseNavigators(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/users/get-nurse-navigators`);
  }

  public forgotPassword(body: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/password-reset/forgot-password`, body).pipe(catchError((err) => of(err)));
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/reset-password`, { token, password });
  }
}
